import api from './index'
import request from '@/utils/request'

// 运行时间统计
export function getRunningTimeData(data) {
    return request.post(api.getRunningTime, data);
}

// 整体统计
export function getOverallData(data) {
    return request.post(api.getOverall, data);
}

// 今日统计
export function getTodayData(data) {
    return request.post(api.getToday, data);
}

// 货柜状态统计
export function getContainerStatusData(data) {
    return request.post(api.getContainerStatus, data);
}

// 查询提单号

export function getBillOfLadingNumberData(data) {
    return request.post(api.getBillOfLadingNumber, data);
}

// 查询集装箱状态判断条件

export function getContainerStatusCondition(data) {
    return request.post(api.getContainerStatusCondition, data);
}

export function getAllocationPalletData(data) {
    return request.post(api.getAllocationPalletData, data);
}

export function getItemInfoByCpbh(data) {
    return request.post(api.getItemInfoByCpbh, data);
}

export function batchImportPalletData(data) {
    return request.post(api.batchImportPalletData, data);
}
