import axios from 'axios'

// create an axios instance
const service = axios.create({
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 500000 // request timeout
})

// request拦截器 request interceptor
service.interceptors.request.use(
    config => {
        // Token追加
        // if (store.getters.token) {
        //     config.headers['X-Token'] = ''
        // }
        config.headers["Content-Type"] = "application/json"
        return config
    },
    error => {
        console.log(error) // for debug
        return Promise.reject(error)
    }
)
// respone拦截器
service.interceptors.response.use(
    response => {
        if (response.status && response.status !== 200) {
            // 登录超时,重新登录
            if (response.status === 401) {
                // store.dispatch('FedLogOut').then(() => {
                //     location.reload()
                // })
            }
            this.$notify.error({ title: '错误', message: response || 'error' });
            return Promise.reject(response.message || 'error')
        } else {
            return Promise.resolve(response.data)
        }
    },
    error => {
        console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)

export default service